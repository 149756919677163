import Step from "@/components/global/Step/Step.vue";
import ProductModal from "../components/ProductModal.vue";
import ProductList from "../components/ProductList.vue";
import {
  getDealerList,
  getGicStockTransBaseInfo,
  gicStockTransSave,
  getDeliveryModeApi,
  getStatsInfo, getTOF, getDeliveryAmountFind
} from "./api";
import {
  getInvStatus,
  getInvStatusTypeApi
} from "@/views/stockCenter/adjustmentApply/components/api";
import { sysconfig } from "@/views/order/channelExamine/api";
import { env, hmptUrl } from "@/common/constant";

export default {
  name: "Home",
  components: {
    Step,
    ProductModal,
    ProductList
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modelShowsale: false,
      // isEnableOrNot:true,
      rowSelectionType: "checkbox", //是否编辑
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入", "提交", "查看进度"],
      orgList: [], //销售组织
      warehousesInList: [], //仓库
      warehousesOutList: [], //仓库
      deliveryModeList: [], //仓库
      addressList: [], //仓库
      addressListAll: [],
      warehouseSelectSHow: false,
      addressSelectSHow: false,
      warehouseSelectOutSHow: false,
      orgSelectShow: false,
      currentStep: 2,
      productList: [],
      orgId: "", //销售组织id
      orgName: "", //销售组织id//
      warehouseId: "", //仓库id
      addressId: "", //仓库id
      warehouseOutId: "", //仓库id
      warehouseOutName: "", //仓库id
      warehouseName: "", //仓库id
      addressName: "", //仓库id
      customerName: "", //仓库id
      customerPhone: "", //仓库id
      deliveryMode: "",
      orgChecked: true,
      warehouseChecked: true,
      addressChecked: true,
      warehouseOutChecked: true,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/adjustmentApply",
          name: "adjustmentApply",
          title: "库存调拨"
        }
      ],
      isLoading: false, // 提交按钮loading
      pageLoadFlag: false,
      ychckId: "",
      yrckID: "",
      shddId: "",
      message: "",//备注
      visibleshop: false,
      salesTabledata: [],
      cherkStatus:false,
      visibleAAA:false,
      showInfo:'',
      msgStatus:'',
      openCloseBtn:false,
      showTips:false,
      warehouseOutType:'',
      warehouseType:'',
      reconciliationPopup: false, // 对账弹窗
      rsKcSheet: [], // 对账列表
    };
  },
  watch: {
    productList: function() {
      let YCnum = 0
      if (this.productList.length > 0 && this.showTIP) {
        for(let i = 0; i < this.productList.length;i++) {
          if(this.productList[i] && this.productList[i].gicWarehouseType && this.productList[i].gicWarehouseType == 20) {
            YCnum+=1
          }
        }
      }
      // 如果选择了统仓的 要展示并且默认
      if(YCnum > 0 && this.showTIP && this.smallLoanSwitch) {
        this.openCloseBtn = true
        this.cherkStatus = true
      } else {
        this.openCloseBtn = false
        this.cherkStatus = false
      }
    },
    type: function () {
      if(this.type == 'allotApply') {
        let obj = this.addressList.find((i)=>{
          return i.value === this.addressList[0].value;//筛选出匹配数据
        });
        // 如果选择的地址状态为D 说明地址失效 弹出提示
        if(obj.areaStatus == 'D') {
          this.addressId = '';
          this.addressName = '';
          this.shddId = ''
          this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
          return
        }
      }
    }
  },
  mounted() {
    this.getLoanSwitch()
    this.getSaleInfo()
    this.getOrgList();
    this.getDeliveryMode();
    this.getisKQ()

  },
  methods: {
    // 去对账
    onOk() {
      this.reconciliationPopup = false
      let url = 'https://cwpt.hisense.com/pt'
      if(env == "dev" || env == "test" || env == "uat") {
        url = 'http://10.19.46.221:7001/tspt'
      }
      window.open(url, '_blank')
    },
    // 关闭对账弹窗
    onCancel() {
      this.reconciliationPopup = false
    },
    getLoanSwitch() {
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.smallLoanSwitch = res.data.data == 'Y' ? true : false
      })
    },
    // 判断当前账户是否签约贷款合同
    getisKQ(){
      let data = {
        serviceCode:'queryDeliveryAmountFind',
        mdmCode:this.$store.state.user.userInfo.customer.customerCode
      }
      getDeliveryAmountFind(data).then(res=>{
        if(res.data.success == 'true'){
          this.showTIP = res.data.msg=='true'?true:false
          this.$forceUpdate()
        }else {
          this.showTIP = false
        }
      })
    },
    changeStatus(){
      this.cherkStatus = !this.cherkStatus
      this.$forceUpdate()
    },
    getSaleInfo() {
      let data = {
        account: this.$store.state.user.userInfo.account.account
      }
      getStatsInfo(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        let arr1 = [];
        let arr2 = [];
        data.data.forEach(item => {
          arr1.push({
            ckun: item.monthlyStatsSalesCount,
            dbnum: item.monthlyStatsTransCount,
            total: item.monthlyStatsTotal,
            dbbl: item.monthlyStatsTransRate,
            dbblxz: item.availableMaxTransRate,
            id: "当前月累 （考核指标）",
            name: item.orgName
          });
          arr2.push({
            name: item.orgName,
            ckun: item.statsTransCount,
            dbnum: item.statsTransCount,
            total: item.statsTotal,
            dbbl: item.statsTransRate,
            dbblxz: item.availableMaxTransRate,
            id: "近 30 天累计（计算规则）"
          });
        });
        this.salesTabledata = arr1.concat(arr2);

        this.salesTabledata.forEach((item, index) => {
          if (index > 2) {
            item.id = "近 30 天累计（计算规则）";
          } else {
            item.id = `当前月累 \xa0\xa0（考核指标）`;
          }
        });
        this.$forceUpdate();
      });
    },
    // 判断是否有免运费标识
    panduanfreeTip() {
      let mian = []
      this.productList.forEach(item => {
        if (item.productLabel && item.productLabel.indexOf('15691143850') !== -1) {
          mian.push(item)
        }
      })
      if (mian.length > 0) {
        this.visibleshop = true
      } else {
        this.submitApply()
      }
    },
    typeChange(type) {
      this.$emit("changeType", type);
    },
    getInvStatusList(item) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        productCode: item.productCode
      };
      return getInvStatus(data);
    },
    getInvStatusType(item) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        productCode: item.productCode
      };
      return getInvStatusTypeApi(data);
    },
    //销售组织前段筛选
    filterOrgOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //仓库前段筛选
    filterWarehouseOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //仓库前段筛选
    addressWarehouseOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //仓库前段筛选
    filterWarehouseOutOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 销售组织点击选择
    orgSelect() {
      this.orgSelectShow = !this.orgSelectShow;
    },
    // 销售组织下拉框选择
    handleChangeOrg(value, e) {
      this.orgId = e.key;
      this.orgName = value;
      this.orgSelect();
      this.getTransBaseInfo();
    },
    // 仓库下拉框选择
    handleChangeWarehouse(value, e) {
      this.warehouseId = e.key;
      this.warehouseName = value;
      let obj = this.warehousesInList.find(it=>{
        return it.value == this.warehouseId
      })
      this.warehouseType = obj.type
      this.warehouseSelect();
    },
    // 仓库下拉框选择
    handleChangeaddress(value, e) {
      let obj = this.addressList.find((i)=>{
        return i.value ===  e.key;//筛选出匹配数据
      });
      // 如果选择的地址状态为D 说明地址失效 弹出提示
      if(obj.areaStatus == 'D') {
        this.addressId = '';
        this.addressName = '';
        this.shddId = ''
        this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
        return
      }
      this.addressId = e.key;
      this.addressName = value;
      this.addressSelect();
      // 获取联系人信息
      this.addressListAll.map(item => {
        if (item.id == e.key) {
          this.customerName = item.linkman;
          this.customerPhone = item.mobile;
        }
      });
    },
    // 仓库下拉框选择
    handleChangeWarehouseOut(value, e) {
      this.warehouseOutId = e.key;
      this.warehouseOutName = value;
      let obj = this.warehousesOutList.find((i)=>{
        return i.value ===  e.key;//筛选出匹配数据
      });
      this.warehouseOutType = obj.type;
      this.warehouseOutSelect();
    },
    //添加商品
    async addProduct(productList) {
      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: "14180000067"
          });
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(it => it.productCode + ',' + it.invStatusName + ',' + it.invStatusTypeName);
        productList.forEach(async it => {
          const uniFlag = it.productCode + ',' + it.invStatusName + ',' + it.invStatusTypeName
          if (!models.includes(uniFlag)) {
            let res = await this.getInvStatusList(it);
            let resData = JSON.parse(res.data);
            it.invStatusList = resData.invStatus.map(item => {
              for (let key in item) {
                return {
                  value: key,
                  name: item[key]
                };
              }
            });
            const newStatus = it.invStatusList.filter(
              item => item.name == it.invStatusName
            );
            if (newStatus.length > 0) {
              it.invStatus = newStatus[0].value;
            }
            res = await this.getInvStatusType(it);
            resData = JSON.parse(res.data);
            it.invTypeList = resData.data.map(item => {
              for (let key in item) {
                return {
                  value: key,
                  name: item[key]
                };
              }
            });
            it.checked = true;
            it.bdemandQty = 1;
            it.maxQty = it.bigQty;
            it.price = 1;
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {
              const uniFlag1 = item.productCode + ',' + item.invStatusName + ',' + item.invStatusTypeName
              if (uniFlag1 == uniFlag) {
                item.bdemandQty += 1
              }
            })
            // this.$message.warning("产品重复,重复产品已去除!");
            // const msg = this.$configTotal({
            //   route: this.$route.fullPath,
            //   id: "14180000068"
            // });
            // this.$message.warning(msg && msg.msgDesc);
          }
        });
      } else {
        let pro = productList[0];
        productList.forEach(async it => {
          const index = this.productList.findIndex(
            it => it.productCode == pro.productCode
          );
          let res = await this.getInvStatusList(it);
          let resData = JSON.parse(res.data);
          it.invStatusList = resData.invStatus.map(item => {
            for (let key in item) {
              return {
                value: key,
                name: item[key]
              };
            }
          });
          const newStatus = it.invStatusList.filter(
            item => item.name == it.invStatusName
          );
          if (newStatus.length > 0) {
            it.invStatus = newStatus[0].value;
          }
          res = await this.getInvStatusType(it);
          resData = JSON.parse(res.data);
          it.invTypeList = resData.data.map(item => {
            for (let key in item) {
              return {
                value: key,
                name: item[key]
              };
            }
          });
          it.checked = true;
          it.bdemandQty = 1;
          it.maxQty = it.bigQty;
          it.price = 1;
          this.$set(this.productList, index, it);

          // }
        });
      }
      // const models = this.productList.map(it => it.productCode);

    },
    // 获取仓库
    getTransBaseInfo() {
      this.addressName = "";
      this.addressId = "";
      this.warehouseOutName = "";
      this.warehouseOutId = "";
      this.warehouseName = "";
      this.warehouseId = "";
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        warehouseType: 70
      };
      getGicStockTransBaseInfo(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.addressListAll = data.data.addressList;
        this.addressList = data.data.addressList.map(item => {
          return {
            value: item.id,
            name: item.address,
            areaStatus:item.areaStatus
          };
        });
        this.warehousesInList = data.data.warehousesIn.map(item => {
          return {
            value: item.cId,
            name: item.name,
            type: item.type,
          };
        });
        this.warehousesOutList = data.data.warehousesOut.map(item => {
          return {
            value: item.cId,
            name: item.name,
            type: item.type,
          };
        });
        if (this.warehousesInList.length > 0) {
          this.warehouseName = this.warehousesInList[0].name;
          this.warehouseId = this.warehousesInList[0].value;
          this.warehouseType= this.warehousesInList[0].type;
          this.warehouseChecked = true;
          this.yrckID = this.warehousesInList[0].name;
        } else {
          this.warehouseName = "";
          this.warehouseId = "";
          this.warehouseType = "";
          this.yrckID = "";
        }
        if (this.warehousesOutList.length > 0) {
          this.warehouseOutName = this.warehousesOutList[0].name;
          this.ychckId = this.warehousesOutList[0].name;
          this.warehouseOutId = this.warehousesOutList[0].value;
          this.warehouseOutType =  this.warehousesOutList[0].type;
          this.warehouseChecked = true;
          this.warehouseOutChecked = true;
        } else {
          this.warehouseOutName = "";
          this.warehouseOutId = "";
          this.warehouseOutType = "";
          this.ychckId = "";
        }
        if (this.addressList.length > 0) {
          if(this.type == 'allotApply') {
            let obj = this.addressList.find((i)=>{
              return i.value === this.addressList[0].value;//筛选出匹配数据
            });
            // 如果选择的地址状态为D 说明地址失效 弹出提示
            if(obj.areaStatus == 'D') {
              this.addressId = '';
              this.addressName = '';
              this.shddId = ''
              this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
              return
            }
          }
          this.addressName = this.addressList[0].name;
          this.shddId = this.addressList[0].name;
          this.addressId = this.addressList[0].value;
          this.warehouseOutChecked = true;
          // 获取联系人信息
          this.addressListAll.map(item => {
            if (item.id == this.addressId) {
              this.customerName = item.linkman;
              this.customerPhone = item.mobile;
            }
          });
        } else {
          this.addressName = "";
          this.addressId = "";
          this.shddId = "";
        }
      });
    },
    // 获取销售组织
    getOrgList() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      getDealerList(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.orgList = data.data.map(item => {
          for (let key in item) {
            return {
              value: key,
              name: item[key]
            };
          }
        });
        if (this.orgList.length > 0) {
          // this.orgName = this.orgList[0].name;
          // this.orgId = this.orgList[0].value;
          // this.orgChecked = true;
          // this.getTransBaseInfo();
        } else {
          this.orgName = "";
          this.orgId = "";
        }
      });
    },
    // 获取配送方式
    getDeliveryMode() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      getDeliveryModeApi(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.deliveryModeList = data.data.map(item => {
          for (let key in item) {
            return {
              value: key,
              name: item[key]
            };
          }
        });
        if (this.deliveryModeList.length > 0) {
          this.deliveryMode = '02'
            // this.deliveryModeList[1].value;
        }
      });
    },
    gotoCK(){
      this.$router.push({
        path:"/stockCenter/myStock"
      });
    },
    // 申请提交
    async submitApply() {
      if(this.openCloseBtn) {
        if (!this.cherkStatus) {
          this.$message.warning("请确认质物明细！");
          return;
        }
      }
      if (!this.orgId) {
        this.$message.warning("请选择销售组织！");
        return;
      }
      if (!this.warehouseOutId) {
        this.$message.warning("请选择移出仓库！");
        return;
      }
      if (!this.warehouseId) {
        this.$message.warning("请选择移入仓库！");
        return;
      }
      if (!this.deliveryMode) {
        this.$message.warning("请选择配送方式！");
        return;
      }
      if (!this.addressId) {
        this.$message.warning("请选择收货地址！");
        return;
      }
      if (!this.customerName) {
        this.$message.warning("请输入联系人！");
        return;
      }
      if (!this.customerPhone) {
        this.$message.warning("请输入联系人手机号！");
        return;
      }
      const staItem = this.productList.filter(it => it.checked);
      if (staItem.length < 1) {
        this.$message.warning("请至少选择一个商品！");
        return;
      }
      let TJmoney = 0
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.price) {
            this.$message.warning(`请检查第${i + 1}个商品价格！`);
            return;
          }
        }
        if(item.gicWarehouseType == '20') {
          TJmoney += (Number(item.bdemandQty) * Number(item.price))
        }

      }
      const orderInfo = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        dataSource: "xtw_pc",
        stockTrans: {
          dataSource: "xtw_pc",
          contact: this.customerName,
          phone: this.customerPhone,
          transactionType: "gicWarehouse",
          orgId: this.orgId,
          gicInWarehouse: this.warehouseId,
          gicInWarehouseName: this.warehouseName,
          gicOutWarehouse: this.warehouseOutId,
          gicOutWarehouseName:this.warehouseOutName,
          gicInWarehouseType:this.warehouseType,
          gicOutWarehouseType:this.warehouseOutType,
          message: this.message,
          deliveryMode: this.deliveryMode,
          addressId: this.addressId
        },
        staItem: staItem.map(item => {
          // bdemandQty调拨数量
          // productCode产品编码
          // price	 价格
          // invStatus库存状态
          // invStatusType补差类型
          // productName产品名称
          return {
            volume: item.volume * item.bdemandQty,
            invStatus: item.invStatus,
            invStatusType: item.invStatusType,
            price: item.price,
            productCode: item.productCode,
            productName: item.productName,
            bdemandQty: item.bdemandQty
            // alertInvStatus: item.alertInvStatus, //调整后库存状态
            // alertInvStatusType: item.alertInvStatusType //调整后补差类型
          };
        })
      };
      this.isLoading = true;
      await gicStockTransSave(orderInfo)
        .then(res => {
          const data = JSON.parse(res.data);
          if (data.code == 0) {
            this.isLoading = false;
            // this.$message.success(`提交成功!`);
            this.$router.push({
              path: "/stockCenter/adjustmentSucces",
              query: {
                b2bOrderCode: data.data,
                orgName: "订单号"
              }
            });
          } else {
            if(data && data.data) {
              const { isExpeir, rsKcSheet } = data.data
              if(isExpeir && rsKcSheet && rsKcSheet.length > 0) {
                this.reconciliationPopup = true
                this.rsKcSheet = rsKcSheet
                this.isLoading = false;
              }else {
                // this.$message.error(data.msg);
                this.visibleAAA = true
                this.showInfo = data.msg
                this.isLoading = false;
              } 
            }else {
              this.visibleAAA = true
              this.showInfo = data.msg
              this.isLoading = false;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //仓库选择
    warehouseSelect() {
      this.warehouseSelectSHow = !this.warehouseSelectSHow;
    },
    //仓库选择
    addressSelect() {
      this.addressSelectSHow = !this.addressSelectSHow;
    },
    //溢出仓库选择
    warehouseOutSelect() {
      this.warehouseSelectOutSHow = !this.warehouseSelectOutSHow;
    },
    // 打开添加商品弹框
    openAdd() {
      // if (!this.orgId) {
      //   this.$message.warning(`请选择销售组织!`);
      //   return;
      // }
      if (!this.warehouseOutId) {
        this.$message.warning(`请先选择移出仓库!`);
        return;
      }
      this.rowSelectionType = "checkbox";
      this.modelShow = true;
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.modelShow = true;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
