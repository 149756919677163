import Step from "@/components/global/Step/Step.vue";
import ProductModal from "../components/ProductModal.vue";
import ProductList from "../components/ProductList.vue";
import axios from "axios";
import { dmsPublicPath } from "@/common/constant.js";
import {
  getDealerList,
  getWarehouseListApi,
  saveOtherStockTrans,
  isEnableOrNotBucha
} from "./api";
import {
  getInvStatus,
  getInvStatusTypeApi
} from "@/views/stockCenter/adjustmentApply/components/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductModal,
    ProductList
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // isEnableOrNot: true,
      rowSelectionType: "checkbox", //是否编辑
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入", "提交", "查看进度"],
      orgList: [], //销售组织
      warehouseList: [], //仓库
      warehouseSelectSHow: false,
      orgSelectShow: false,
      currentStep: 2,
      productList: [],
      orgId: "", //销售组织id
      orgName: "", //销售组织id//
      warehouseId: "", //仓库id
      warehouseName: "", //仓库id
      orgChecked: true,
      warehouseChecked: true,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/adjustmentApply",
          name: "adjustmentApply",
          title: "库存调整"
        }
      ],
      isLoading: false, // 提交按钮loading
      pageLoadFlag: false,
      fhckId: "",
      message: "",// 备注
      downloadUrl: `${process.env.BASE_URL}downLoadTemplate/otherOutInImport_gic.xls`,

    };
  },
  watch: {
    type: {
      immediate: true,
      handler(newValue) {
        if (newValue == "inApply") {
          this.breadcrumbData[1].title = "其他入库";
          this.type = "inApply";
        }
        if (newValue == "outApply") {
          this.breadcrumbData[1].title = "其他出库";
          this.type = "outApply";
        }
        if (newValue == "statusApply") {
          this.breadcrumbData[1].title = "状态调整";
        }
      }
    },
  },
  mounted() {
    this.getOrgList();


  },
  methods: {
    // 下载模板
    downFile() {
      const link = document.createElement("a");
      link.href = this.downloadUrl;
      if (this.type == "outApply") {
        link.setAttribute("download", "其他出库.xls");
      }
      if (this.type == "inApply") {
        link.setAttribute("download", "其他入库.xls");
      }
      link.click();
    },

    // 导入数据 
    importExcel(fileList) {
      let type = this.type == "outApply" ? "otherOut" : "otherIn";
      let account = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.account.account : JSON.parse(localStorage.getItem("userInfo")).account.account;
      let cisCode = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.customer.cisCode : JSON.parse(localStorage.getItem("userInfo")).customer.cisCode;
      let formData = new FormData();
      formData.append("cisCode", cisCode);
      formData.append("transactionType", type);
      formData.append("orgCode", this.orgId);
      formData.append("gicOutWarehouse", this.warehouseId);
      formData.append("file", fileList[0].originFileObj);
      let config = {
        headers: { "content-type": "multipart/form-data" }
      };
      // 发送请求
      const BASE_API = dmsPublicPath;
      axios.post(BASE_API + "/wechatEntrance/entrance.do" + "?account=" + account + "&method=importStockTransOutInProduct" + "&type=1", formData, config).then(res => {
        let importList = JSON.parse(res.data).data;
        // 上传文件错误弹出提示
        if (JSON.parse(res.data).code == 1) {
          this.$message.warning(JSON.parse(res.data).msg);
        }

        importList.forEach(item => {
          if (item.errorLog) {
            this.$message.warning(item.errorLog);
          } else {
            let list = [];
            if (this.productList.length <= 0) {
              list.push({
                model: item.model,
                materialGroupCode: item.materialGroupCode,
                colour: item.colour,
                volume: item.volume,
                invStatusId: item.invstatusId,
                invStatus: item.invstatusId,
                invStatusName: item.invstatus,
                invStatusType: item.invStatusType,
                invStatusTypeName: item.invStatusTypeName,
                bigQty: item.bavailBqty,
                bdemandQty: item.backnowledgedQty,
                productCode: item.productCode,
                productName: item.productName,
                price: item.price,
                checked: true,
                maxQty: this.type == "inApply" ? 99999999 : item.bavailBqty,
                alertInvStatus:
                  this.type == "statusApply" ? item.alertInvStatus : "", //调整后库存状态
                alertInvStatusType:
                  this.type == "statusApply" ? item.alertInvStatusType : "" //调整后补差类型
              });
            } else {

              // 已经存在的产品的cod集合
              const models = this.productList.map(
                it =>
                  it.productCode
              );
              // 导入的数据的code集合
              const uniFlag =
                  item.productCode
              ;
              this.productList.forEach(proItem1 => {
                // 如果之前的商品列表数组大于0并且之前的商品列表中不包含导入的产品
                if (this.productList.length > 0 && (!models.includes(uniFlag))) {
                  list.push({
                    model: item.model,
                    materialGroupCode: item.materialGroupCode,
                    colour: item.colour,
                    volume: item.volume,
                    invStatusId: item.invstatusId,
                    invStatus: item.invstatusId,
                    invStatusName: item.invstatus,
                    invStatusType: item.invStatusType,
                    invStatusTypeName: item.invStatusTypeName,
                    bigQty: item.bavailBqty,
                    bdemandQty: item.backnowledgedQty,
                    productCode: item.productCode,
                    productName: item.productName,
                    price: item.price,
                    checked: true,
                    maxQty: this.type == "inApply" ? 99999999 : item.bavailBqty,
                    alertInvStatus:
                      this.type == "statusApply" ? item.alertInvStatus : "", //调整后库存状态
                    alertInvStatusType:
                      this.type == "statusApply" ? item.alertInvStatusType : "" //调整后补差类型
                  });
                //   如果之前的商品列表数组大于0并且之前的商品列表中包含导入的产品 就进行数据的叠加等操作
                } else if (this.productList.length > 0 && (models.includes(uniFlag))) {
                  const uniFlag1 =
                    proItem1.productCode;
                  // 如果导入的数据和之前存在的数据一致因为直接添加没有选择补差类型和质量等级所以要赋值，数量进行叠加
                  if (uniFlag1 == uniFlag) {
                      proItem1.bdemandQty += item.backnowledgedQty;
                      proItem1.invStatusId=item.invstatusId;
                      proItem1.invStatus = item.invstatusId;
                      proItem1.invStatusName = item.invstatus;
                      proItem1.invStatusType=item.invStatusType;
                      proItem1.alertInvStatus= this.type == "statusApply" ? item.invstatusId : ""
                      proItem1.alertInvStatusType= this.type == "statusApply" ? item.invStatusType : ""
                    }
                  }
              });
            }
            list.forEach(async proItem => {
              let res = await this.getInvStatusList(proItem);
              let resData = JSON.parse(res.data);
              proItem.invStatusList = resData.invStatus.map(item => {
                for (let key in item) {
                  return {
                    value: key,
                    name: item[key]
                  };
                }
              });
              const newStatus = proItem.invStatusList.filter(
                item => item.name == proItem.invStatusName
              );
              if (newStatus.length > 0) {
                proItem.invStatus = newStatus[0].value;
              }
              res = await this.getInvStatusType(proItem);
              resData = JSON.parse(res.data);
              proItem.invTypeList = resData.data.map(item => {
                for (let key in item) {
                  return {
                    value: key,
                    name: item[key]
                  };
                }
              });
              this.productList.push(proItem);
            });
          }
        });
      });
    },
    fileUpload(fileList) {
      this.file = fileList;
    },
    typeChange(type) {
      this.$emit("changeType", type);
    },
    getInvStatusList(item) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        productCode: item.productCode,
        havePrototype:0
      };
      return getInvStatus(data);
    },
    getInvStatusType(item) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        productCode: item.productCode
      };
      return getInvStatusTypeApi(data);
    },
    //销售组织前段筛选
    filterOrgOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //仓库前段筛选
    filterWarehouseOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 销售组织点击选择
    orgSelect() {
      this.orgSelectShow = !this.orgSelectShow;
    },
    // 销售组织下拉框选择
    handleChangeOrg(value, e) {
      this.orgId = e.key;
      this.orgName = value;
      this.orgSelect();
      this.getWarehouseList();
    },
    // 仓库下拉框选择
    handleChangeWarehouse(value, e) {
      this.warehouseId = e.key;
      this.warehouseName = value;
      this.warehouseSelect();
    },
    //添加商品
    async addProduct(productList) {

      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: "14180000067"
          });
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(
          it =>
            it.productCode
          // + "," + it.invStatusName + "," + it.invStatusTypeName
        );
        productList.forEach(async it => {
          // 新增加的数据的体积，用来给导入数据赋值 否则体积为0
          const volome =
            it.volume;
          // 可用库存
          const bigQty =
            it.bigQty;
          const uniFlag =
              it.productCode
            // +
            // "," +
            // it.invStatusName +
            // "," +
            // it.invStatusTypeName
          ;
          if (!models.includes(uniFlag)) {
            let res = await this.getInvStatusList(it);
            let resData = JSON.parse(res.data);
            it.invStatusList = resData.invStatus.map(item => {
              for (let key in item) {
                return {
                  value: key,
                  name: item[key]
                };
              }
            });
            const newStatus = it.invStatusList.filter(
              item => item.name == it.invStatusName
            );
            if (newStatus.length > 0) {
              it.invStatus = newStatus[0].value;
            }
            res = await this.getInvStatusType(it);
            resData = JSON.parse(res.data);
            it.invTypeList = resData.data.map(item => {
              for (let key in item) {
                return {
                  value: key,
                  name: item[key]
                };
              }
            });
            it.checked = true;
            it.bdemandQty = 1;
            if (this.type == "inApply") {
              it.maxQty = 99999999;
            } else {
              it.maxQty = it.bigQty;
            }
            it.price = 1;
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {

              const uniFlag1 =
                item.productCode;
              if (uniFlag1 == uniFlag) {
                debugger
                item.bdemandQty += 1;
                if(Number(item.volume)==0) {
                  item.volume = volome
                }
                if(Number(item.bigQty)==0) {
                  item.bigQty = bigQty
                  if (this.type == "inApply") {
                    it.maxQty = 99999999;
                  } else {
                    it.maxQty = bigQty;
                  }
                }
              }
            });
            // this.$message.warning("产品重复,重复产品已去除!");
            // const msg = this.$configTotal({
            //   route: this.$route.fullPath,
            //   id: "14180000068"
            // });
            // this.$message.warning(msg && msg.msgDesc);
          }
          // if (!uniqueFlags.includes(it.uniqueFlag)) {

          // }
        });
      } else {
        let pro = productList[0];
        productList.forEach(async it => {
          const index = this.productList.findIndex(
            it => it.productCode == pro.productCode
          );
          let res = await this.getInvStatusList(it);
          let resData = JSON.parse(res.data);
          it.invStatusList = resData.invStatus.map(item => {
            for (let key in item) {
              return {
                value: key,
                name: item[key]
              };
            }
          });
          const newStatus = it.invStatusList.filter(
            item => item.name == it.invStatusName
          );
          if (newStatus.length > 0) {
            it.invStatus = newStatus[0].value;
          }
          res = await this.getInvStatusType(it);
          resData = JSON.parse(res.data);
          it.invTypeList = resData.data.map(item => {
            for (let key in item) {
              return {
                value: key,
                name: item[key]
              };
            }
          });
          it.checked = true;
          it.bdemandQty = 1;
          if (this.type == "inApply") {
            it.maxQty = 99999999;
          } else {
            it.maxQty = it.bigQty;
          }
          it.price = 1;
          // pro = {
          //   ...it,
          //   checked: true,
          //   itemId: "",
          //   bprice: this.productList[index].bprice
          // };
          this.$set(this.productList, index, it);
          // }
        });
      }

      // const uniqueFlags = this.productList.map(it => it.uniqueFlag);
    },
    // 获取仓库
    getWarehouseList() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgId: this.orgId,
        warehouseType: 70
      };
      getWarehouseListApi(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.warehouseList = data.data.map(item => {
          return {
            value: item.cId,
            name: item.name
          };
        });
        if (this.warehouseList.length > 0) {
          this.warehouseName = this.warehouseList[0].name;
          this.warehouseId = this.warehouseList[0].value;
          this.fhckId = this.warehouseList[0].name;
          this.warehouseChecked = true;
        } else {
          this.warehouseName = "";
          this.warehouseId = "";
          this.fhckId = "";
        }
      });
    },
    // 获取销售组织
    getOrgList() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      getDealerList(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.orgList = data.data.map(item => {
          for (let key in item) {
            return {
              value: key,
              name: item[key]
            };
          }
        });
        if (this.orgList.length > 0) {
          this.orgName = this.orgList[0].name;
          this.orgId = this.orgList[0].value;
          this.orgChecked = true;
          this.getWarehouseList();
        } else {
          this.orgName = "";
          this.orgId = "";
        }
      });
    },
    // 申请提交
    submitApply() {


      if (!this.orgId) {
        this.$message.warning("请选择销售组织！");
        return;
      }
      if (!this.warehouseId) {
        this.$message.warning("请选择移入仓库！");
        return;
      }
      const staItem = this.productList.filter(it => it.checked);
      if (staItem.length < 1) {
        this.$message.warning("请至少选择一个商品！");
        return;
      }
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.invStatus && this.type == "inApply") {
            this.$message.warning(`请检查第${i + 1}个商品质量等级！`);
            return;
          }
          // if ((!item.invStatusType && this.type == "inApply")&&this.isEnableOrNot) {
          //   this.$message.warning(`请检查第${i + 1}个商品补差类型！`);
          //   return;
          // }
          if (!item.alertInvStatus && this.type == "statusApply") {
            this.$message.warning(`请检查第${i + 1}个商品质量等级！`);
            return;
          }
          // if (!item.alertInvStatusType && this.type == "statusApply") {
          //   this.$message.warning(`请检查第${i + 1}个商品补差类型！`);
          //   return;
          // }
          if (!item.price) {
            this.$message.warning(`请检查第${i + 1}个商品价格！`);
            return;
          }
        }
      }
      const transactionType =
        this.type == "statusApply"
          ? "invStatus"
          : this.type == "inApply"
            ? "otherIn"
            : "otherOut";
      const orderInfo = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        dataSource: "xtw_pc",
        stockTrans: {
          dataSource: "xtw_pc",
          transactionType: transactionType,
          orgId: this.orgId,
          gicInWarehouse: this.type == "outApply" ? "" : this.warehouseId,
          gicOutWarehouse: this.type == "inApply" ? "" : this.warehouseId,
          message: this.message,
          deliveryMode: "01"
        },
        staItem: staItem.map(item => {
          // bdemandQty调拨数量
          // productCode产品编码
          // price	 价格
          // invStatus库存状态
          // invStatusType补差类型
          // productName产品名称
          return {
            invStatus: item.invStatusId,
            // invStatusType: this.isEnableOrNot?item.invStatusType:'',
            invStatusType: item.invStatusType,
            price: item.price,
            productCode: item.productCode,
            productName: item.productName,
            bdemandQty: item.bdemandQty,
            alertInvStatus:
              this.type == "statusApply" ? item.alertInvStatus : "", //调整后库存状态
            alertInvStatusType:
              this.type == "statusApply" ? item.alertInvStatusType : "" //调整后补差类型
          };
        })
      };
      this.isLoading = true;
      saveOtherStockTrans(orderInfo)
        .then(res => {
          const data = JSON.parse(res.data);
          if (data.code == 0) {
            // debugger
            // this.$message.success(`提交成功!`);
            this.$router.push({
              path: "/stockCenter/adjustmentSucces",
              query: {
                b2bOrderCode: data.data,
                orgName: "订单号"
              }
            });
          } else {
            this.$message.warning(data.msg);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //仓库选择
    warehouseSelect() {
      this.warehouseSelectSHow = !this.warehouseSelectSHow;
    },
    // 打开添加商品弹框
    openAdd() {
      if (!this.orgId) {
        this.$message.warning(`请选择销售组织!`);
        return;
      }
      if (!this.warehouseId) {
        this.$message.warning(`请选择仓库!`);
        return;
      }
      this.rowSelectionType = "checkbox";
      this.modelShow = true;
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.modelShow = true;
    },
    goBack() {
      this.$router.go(-1);
      window.close();
    }
  }
};
