<template>
  <div>
    <div class="prod-list-wrap">
      <div class="title-wrap">
          <div class="aa"><img src="./../../../../assets/order/产品信息@2x.png"></div>
          <span>商品信息</span>
          <div class="freeText">
            <p class="text">({{cartInfoTip}})</p>
          </div>
           <a-upload
            class="btn"
            name="file"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @change="handleChange"
            :showUploadList = false
            
          >
            <h-btn
              v-if="type =='outApply' || type == 'inApply'"
              class="btn"
              :width="66"
              :height="28"
              content="导入"
              level="1"
              type="primary"
              fontSize="16px"
              borderRadius="4"
              style="margin-left:893px"
          />
          </a-upload>
           <h-btn
              v-if="type =='outApply' || type =='inApply'"
              style="margin-left:24px"
              class="btn"
              :width="88"
              :height="28"
              content="下载模板"
              level="1"
              type="primary"
              fontSize="16px"
              borderRadius="4"
              @click="downFile"
              :showUploadList = false
          />

      </div>
      <div class="product-list-wrap clearfix">
        <div
          class="commodity-item"
          v-for="(item, index) of productList"
          :key="index"
        >
          <div class="commodity-item-check">
<!--            <a-checkbox-->
<!--              :checked="item.checked"-->
<!--              @change="itemClick(item, $event)"-->
<!--            ></a-checkbox>-->
          </div>
          <div class="commodity-item-detail">
            <div class="pro-img">
              <h-img2
                class="img"
                :imgUrl="'/product/pic.nd?productId=' + item.productCode"
                :errorImg="
                '/assets/new/images/product/' + item.materialGroupCode + '.jpg'
              "
                :isUseBasePath="true"
                alt=""
              />
            </div>
            <div class="commodity-item-title">
              <p><span  v-if="type == 'allot' && item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping" >免运费</span>{{ item.model }}</p>
              <span class="itemOne">品牌：{{ item.brandName }}</span>
              &nbsp;&nbsp;&nbsp;
              <span>颜色：{{ item.colour }}</span>
              <br />
              <span>体积：{{ item.volume }}m³</span>
            </div>
          </div>
          <div class="commdity-item-jiage">
            <p class="sizeLevel" v-if="type != 'inApply'">
              <span>质量等级：{{ item.invStatusName }}</span>
<!--              <span v-if="item.invStatusTypeName">补差类型：{{ item.invStatusTypeName }}</span>-->
            </p>
          </div>
          <div class="commdity-item-jiage">
            <p class="sizeLevel" v-if="type != 'inApply'">
              <span>可用库存：{{ item.bigQty }}</span>
            </p>
          </div>
          <div class="commdity-item-jiage commdity-item-total">
            <!--            <span @click="edit(item)">编辑</span>-->
            <!-- 删除 -->
            <div class="close">
              <a-popconfirm
                placement="topRight"
                title="确认删除该商品？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delProduct(index)"
              >
                <i class="iconfont icon-guanbi-gouwuche"></i>
              </a-popconfirm>
            </div>
          </div>
          <div
            :class="type == 'statusApply'? 'typeing-wrap down':'typeing-wrap down1'"
            :style="{ width: (type == 'statusApply'||type == 'inApply') ? '1010px' : '433px' }"
          >
            <span v-if="type == 'statusApply'">
              <span class="lab">质量等级调整为：</span>
              <a-select class="chose-account" v-model="item.alertInvStatus" allowClear>
                <a-select-option
                  :value="it.value"
                  v-for="it in item.invStatusList"
                  :key="it.value"
                  >{{ it.name }}
                </a-select-option>
              </a-select>
            </span>
            <span v-if="type == 'statusApply'">
              <span class="lab">补差类型调整为：</span>
              <a-select class="chose-account" v-model="item.alertInvStatusType" allowClear>
                <a-select-option
                  :value="it.value"
                  v-for="it in item.invTypeList"
                  :key="it.value"
                  >{{ it.name }}
                </a-select-option>
              </a-select>
            </span>
            <span v-if="type == 'inApply'">
              <span class="lab">质量等级：</span>
              <a-select
                  allowClear
                class="chose-account"
                v-model="item.invStatus"
                @change="getInvQtyList(item)"
              >
                <a-select-option
                  :value="it.value"
                  v-for="it in item.invStatusList"
                  :key="it.value"
                  >{{ it.name }}
                </a-select-option>
              </a-select>
            </span>
            <span v-if="type == 'inApply'">
              <span class="lab">补差类型：</span>
              <a-select
                  allowClear
                class="chose-account"
                v-model="item.invStatusType"
                @change="getInvQtyList(item)"
              >
                <a-select-option
                  :value="it.value"
                  v-for="it in item.invTypeList"
                  :key="it.value"
                  >{{ it.name }}
                </a-select-option>
              </a-select>
            </span>
            <span class="lab">调整数量：</span>
            <number-box
              class="number-box"
              :curVal.sync="item.bdemandQty"
              :maxVal="item.maxQty"
            />
            <span class="lab">单价：</span>
            <a-input-number style="width: 120px!important" :min="0" v-model="item.price" />
          </div>
        </div>
      </div>
      <div class="modelClick" @click="openAdd">+新增商品</div>
    </div>
    <div class="sum-wrap-wrap">
      <div class="sum-wrap">
        <p class="info">
          共 {{ getTotalProd() }} 件商品 体积合计{{
            $util.getFloat(getTotalVol(), 3)
          }}m³
          <a-popover placement="topRight">
            <template slot="content">
              <p>起运量标准：单电视商家5台，白电及全品类商家3m³s</p>
            </template>
            <template slot="title">
              <span>物流费用规则</span>
            </template>
            <i class="iconfont icon-tijiwenti-pc problemfang"></i>
          </a-popover>
        </p>
        <!--        <p class="info">-->
        <!--          合计：-->
        <!--          <span>{{ $util.getFloat(getTotalMoney(), 2) }}元</span>-->
        <!--        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getInvQty } from "./api.js";

export default {
  name: "ProductList",
  props: {
    productList: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    // isEnableOrNot: {
    //   type: Boolean,
    //   required: true
    // },
    warehouseId: {
      type: String
    }
  },
  data() {
    return {
      invStatusList: [],
      qualitylvList: [],
      fileList: [],
      cartInfoTip:''
    };
  },
  mounted() {
    this.getMessage()
  },
  methods: {
     getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    // 下载模板
    downFile() {
      this.$emit('downFile')
    },
    // 导入商品信息
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
      this.fileList = this.fileList.slice(-1);
      if( this.fileList.length > 0) {
        this.$emit("importFile", this.fileList);
      }
    },
    getInvQtyList(item) {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        productCode: item.productCode,
        warehouseId: this.warehouseId,
        invStatusType: item.invStatusType,
        invStatusId: item.invStatus,
        invBatchId: ""
      };
      getInvQty(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // item.maxQty = data.bavailqty
        // item.bigQty = data.bavailqty
      });
    },
    // 选中商品
    itemClick(item, e) {
      item.checked = e.target.checked;
      this.$forceUpdate();
    },
    //删除商品
    delProduct(index) {
      this.productList.splice(index, 1);
    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.bdemandQty || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.bdemandQty || 0) * (item.volume || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        if (item.checked && !isNaN(item.bprice)) {
          return sum + (item.bdemandQty || 0) * (item.bprice || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getxj(bprice, bdemandQty, item) {
      item.amount = new Number((bprice || 0) * (bdemandQty || 0)).toFixed(2);
      return item.amount;
    },
    openAdd() {
      this.$emit("openAdd");
    },
    //编辑
    edit(item) {
      this.$emit("edit", item);
    }
  }
};
</script>

<style lang="less" scoped>
.prod-list-wrap {
  width: 1188px;
  margin: 16px auto;
  margin-bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  box-sizing: border-box;

  .title-wrap {
    //line-height: 22px;
    //padding-top: 24px;
    padding-left: 24px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1164px;
    height: 44px;
    background: linear-gradient(270deg, rgba(255,255,255,0.21) 0%, #ECF8F8 99%);
    border: 2px solid #FFFFFF;
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    .freeText {
      position: absolute;
      left: 131px;
      .text {
        font-size: 12px;
        font-weight: 400;
        color:#FF8F3C;
      }
    }
  }

  .product-list-wrap {
    width: 1140px;
    margin: 0 auto;

    div {
      display: inline-block;
    }

    .commodity-item {
      position: relative;
      //border: 1px solid #eeeeee;
      margin-top: 16px;
      padding: 22px 24px;
      padding-left: 0;
      box-sizing: border-box;
      float: left;
      width: 1140px;
      height: 218px;
      background: #F7F7F7;
      border-radius: 4px;
      //display: flex;
      //justify-content: flex-start;
      // align-items: center;
      //.typeing-wrap {
      //  position: absolute;
      //  background: #f2f3f5;
      //  bottom: 18px;
      //  left: 48px;
      //  line-height: 62px;
      //  text-align: left;
      //  width: 1005px;
      //  height: 62px;
      //  background: #ECF8F8;
      //  border: 1px solid rgba(0,170,166,0.24);
      //  background: #ECF8F8;
      //
      //  //&:after {
      //  //  box-sizing: content-box;
      //  //  width: 0;
      //  //  height: 0;
      //  //  position: absolute;
      //  //  top: -27px;
      //  //  left: 48px;
      //  //  padding: 0;
      //  //  border-bottom: 14px solid #ECF8F8;
      //  //  border-top: 14px solid transparent;
      //  //  border-left: 14px solid transparent;
      //  //  border-right: 14px solid transparent;
      //  //  display: block;
      //  //  content: "";
      //  //  z-index: 10;
      //  //}
      //
      //
      //  .lab {
      //    margin-left: 24px;
      //  }
      //
      //  .chose-account {
      //    width: 150px;
      //  }
      //
      //  .number-box {
      //    width: 170px;
      //    margin-left: 24px;
      //  }
      //}
      .typeing-wrap {
          position: absolute;
          bottom: 18px;
          left: 24px;
          line-height: 56px;
          text-align: left;
          width: 1005px;
          height: 56px;
          background: #ECF8F8;
          border: 1px solid rgba(0,170,166,0.24);
          background: #ECF8F8;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .lab {
          margin-left: 24px;
          margin-right: 4px;
        }
        .ant-input-number {
          border-radius: 4px;
          border: 1px solid rgba(0,170,166,0.26);
        }

        .chose-account {
          width: 150px;
          /deep/ .ant-select-selection {
            border: 1px solid rgba(0,170,166,0.26);
            border-radius: 4px;
          }
        }

        .number-box {
          width: 117px;
          //height: 32px;
          //line-height: 32px;
          display: flex;
          align-items: center;
          /deep/ .btn-number {
            background: #20C6BA ;
            color: #ffffff;
            height: 32px;
            line-height: 32px;
            width: 29px;
          }

          /deep/ .number-container {
            max-width: 54px !important;
            height: 32px;
            border-top: 1px solid #00AAA6;
            border-bottom: 1px solid #00AAA6;
          }

          //margin-top: 22px;
        }
      }

      .typeing-wrap:before,
      .typeing-wrap:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border:0px solid transparent;
        pointer-events: none;
        content: "";
        border-color: rgba(136, 183, 213, 0);
      }

      .typeing-wrap.down:before {
        border-bottom-color: #BBE4E3;
        border-width: 12px;
        left: 13%;
        margin-left: -76.5px;
        bottom: 100%;
      }

      .typeing-wrap.down:after {
        border-bottom-color: #ECF8F8;
        border-width: 10px;
        left: 13.5%;
        margin-left: -80px;
        bottom: 100%;
      }
      .typeing-wrap.down1:before {
        border-bottom-color: #BBE4E3;
        border-width: 12px;
        left: 13%;
        margin-left: -20.5px;
        bottom: 100%;
      }

      .typeing-wrap.down1:after {
        border-bottom-color: #ECF8F8;
        border-width: 10px;
        left: 13.5%;
        margin-left: -20px;
        bottom: 100%;
      }
    }

    .commodity-item-check {
      //width: 30px;
      min-height: 20px;
      margin-right: 24px;
      line-height: 98px;
      float: left;

      /deep/ .ant-checkbox-input {
        width: 20px;
        height: 20px;
      }
    }

    .commodity-item-detail {
      margin-right: 0px;
      width: 400px;
      float: left;

      .pro-img {
        margin-right: 16px;
        float: left;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        .img{
          width: 80px;
          height: 80px
        }
      }

      .commodity-item-title {
        float: left;
        text-align: left;

        p {
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          margin-top: 15px;
          margin-bottom: 8px;
          .freeShipping{
            margin-left: 0px;
            font-size: 12px;
            color: #fff;
            width: 42px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
            border-radius: 2px;
            margin-top: 9px;
            margin-right: 4px;
            font-weight: 400;
          }
        }

        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;

          .itemOne {
            margin-right: 10px;
          }
        }
      }
    }

    .commdity-item-jiage {
      // width: 200px;
      margin-top: 15px;
      margin-right: 60px;
      float: left;

      .size {
        float: left;
        margin-bottom: 11px;

        input {
          display: inline-block;
          width: 80px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #cccccc;
          text-align: center;
          line-height: 28px;
          margin-right: 5px;
        }

        /deep/ .ant-input:focus {
          border-color: rgba(0, 170, 166, 1) !important;
        }
      }

      .sizeLevel {
        margin-top: 32px;
        font-size: 12px;
        font-weight: 400;
        color: #aaaaaa;

        span {
          margin-right: 10px;
        }
      }
    }

    .commdity-item-num {
      margin-top: 10px;

      .number-box {
        height: 38px;
        float: left;
        margin-bottom: 6px;

        // margin-bottom: 11px;
        /deep/ .btn-number {
          line-height: 40px;
        }
      }

      .sizeLevel {
        span {
          margin-right: 10px;
        }
      }
    }

    .commdity-item-total {
      text-align: left;
      float: right;
      margin-right: 0;
      text-align: left;
      float: right;
      margin-right: 0;
      display: flex;
      width: 100px;
      justify-content: flex-end;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 5px;
      }

      span {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        color: #777777;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
      }
    }

    .close {
      // margin-top: 30px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #aaaaaa;
      // background-color: rgb(110, 112, 112);
      cursor: pointer;

      i {
        font-size: 20px;
      }
    }

    .close:hover {
      // background-color: #00AAA6;
      background-image: url(../../../../assets/cart/close.png);
      color: #fff;
      background-size: 100%;
    }
  }

  .modelClick {
    width: 460px;
    height: 38px;
    background: #ffffff;
    border: 1px dashed #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto 8px auto;
    font-size: 14px;
    font-weight: 400;
    color: #1890FF;
    cursor: pointer;
    border-radius: 4px;
  }
  .modelClick:hover {
    color: #1890FF;
    border: 1px dashed #1890FF;
    background: rgba(24, 144, 255, 0.08);
  }

}

.sum-wrap-wrap {
  width: 1188px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  // margin-bottom: 16px;

  .sum-wrap {
    width: 1140px;
    background: #f7f7f7;
    margin: 0 auto;
    padding: 17px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      text-align: right;
      color: #777777;
      font-size: 12px;
      line-height: 24px;

      span {
        color: #262626;
      }

      .problemfang {
        margin-left: 6px;
        color: #0caeaa;
        cursor: pointer;
      }

      .yufu {
        margin-right: 80px;
      }
    }
  }
}
</style>
