import statusApply from "./status/index.vue";
import allotApply from "./allot/index.vue";
export default {
  name: "AdjustmentApply",
  components: {
    statusApply,
    allotApply
  },
  data() {
    return {
      type: "allotApply"
    };
  },
  methods: {
    changeType(type) {
      this.type = type;
    }
  }
};
